const App = () => {
  return (
    <div className="container">
      <div>Artur Frącala</div>
      <div>Software Development</div>
      <div className="contact">contact@fracalasoftware.com</div>
    </div>
  );
};

export default App;
